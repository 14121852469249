var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", staticClass: "t-v-slide" },
    [
      _c(
        "div",
        {
          staticClass: "t-v-slide__content",
          class:
            ((_obj = {}),
            (_obj["t-v-slide__content--" + _vm.cursor] = _vm.cursor),
            _obj),
          style: _vm.contentStyle,
          on: {
            touchstart: _vm.onMouseDown,
            mousedown: _vm.onMouseDown,
            mouseup: _vm.onMouseUp,
            touchend: _vm.onMouseUp,
            mousemove: _vm.onMouseMove,
            touchmove: _vm.onMouseMove,
          },
        },
        [
          _vm.isTeamNameVisible
            ? _c("div", { staticClass: "t-v-slide__team-name" }, [
                _c("div", { staticClass: "ellipsis" }, [
                  _vm._v(" " + _vm._s(_vm.slide.teamName) + " "),
                ]),
              ])
            : _vm._e(),
          _vm.ratingVisible
            ? _c(
                "div",
                { staticClass: "t-v-slide__rating" },
                [
                  _vm._v(" Vote "),
                  _c("StarRating", {
                    staticClass: "t-v-slide__vue-star-rating",
                    attrs: {
                      "read-only": false,
                      increment: 0.5,
                      padding: 1,
                      "star-size": 20,
                      "show-rating": false,
                      "border-width": 0,
                      "inactive-color": _vm.$theme.get("--disabled-color"),
                      "active-color": "#fff",
                      "border-color": "transparent",
                    },
                    on: {
                      "rating-selected": function ($event) {
                        return _vm.$emit("rated", {
                          rate: $event,
                          data: _vm.slide,
                        })
                      },
                    },
                  }),
                  _vm.isScoreVisible
                    ? _c("div", { staticClass: "t-v-slide__pts" }, [
                        _vm._v(" " + _vm._s(_vm.slide.score || 0) + " PTS "),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "t-v-slide__wrapper" },
            [
              _vm.isMediaContent
                ? _c("TheaterVotingSlideAsset", {
                    ref: "asset",
                    class:
                      ((_obj$1 = {}),
                      (_obj$1["t-v-slide__cursor--" + _vm.cursor] = _vm.cursor),
                      _obj$1),
                    style: _vm.zoomableStyle,
                    attrs: {
                      id: _vm.id,
                      src: _vm.src,
                      srcOverride: _vm.overrideImageUrl,
                      disabled: _vm.action && _vm.action !== _vm.Action.Default,
                      volume: _vm.volume,
                    },
                    on: { ready: _vm.onReady },
                  })
                : _c("ResizableText", {
                    staticClass: "w-full h-full",
                    attrs: { message: _vm.slide.correct },
                  }),
            ],
            1
          ),
          _vm.dimensions && !_vm.overrideImageUrl
            ? _c(
                "div",
                {
                  staticClass: "t-v-slide__controls",
                  staticStyle: { "pointer-events": "none" },
                  on: {
                    mouseover: _vm.onControlsMouseOver,
                    mouseleave: _vm.onControlsMouseLeave,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "t-v-slide__controls-row" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "content-class": "--sm" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g({}, on),
                                      [
                                        _vm.isPreferredHost
                                          ? _c("SettingsSelector", {
                                              staticClass: "mr-2",
                                              staticStyle: { width: "50px" },
                                              attrs: {
                                                items: _vm.cursorOptions,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "selected-item",
                                                    fn: function (ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              size: "14",
                                                              dark: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.icon
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                  {
                                                    key: "item",
                                                    fn: function (ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "text-sm mr-2",
                                                            attrs: {
                                                              size: "14",
                                                              dark: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.icon
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "mr-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "ml-auto",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.hint
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.action,
                                                callback: function ($$v) {
                                                  _vm.action = $$v
                                                },
                                                expression: "action",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            408747051
                          ),
                        },
                        [
                          _c("span", { staticClass: "bottom" }, [
                            _vm._v(" Select image cursor "),
                          ]),
                        ]
                      ),
                      _vm.canDraw
                        ? [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "content-class": "--sm" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "SettingsSelector",
                                                {
                                                  ref: "colorPalette",
                                                  staticClass: "mr-2",
                                                  staticStyle: {
                                                    width: "50px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selected-item",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  size: "14",
                                                                  dark: "",
                                                                  color:
                                                                    _vm.lineColor,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " fa-palette "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "px-3 pb-3 pt-1",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-bold mb-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Annotation Tool Color"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("ColorPalette", {
                                                        model: {
                                                          value: _vm.lineColor,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.lineColor = $$v
                                                          },
                                                          expression:
                                                            "lineColor",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4066855951
                                ),
                              },
                              [
                                _c("span", { staticClass: "bottom" }, [
                                  _vm._v(" Select brush color "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text--lighten-1 grey--text ml-4",
                                    },
                                    [_vm._v("5")]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "content-class": "--sm" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "SettingsSelector",
                                                {
                                                  ref: "brushSize",
                                                  staticStyle: {
                                                    width: "50px",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selected-item",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mx-1",
                                                                attrs: {
                                                                  size:
                                                                    _vm.lineWidth +
                                                                    2,
                                                                  dark: "",
                                                                  color:
                                                                    _vm.lineColor,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " circle "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "px-3 pb-3 pt-1",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "font-bold mb-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Annotation Tool Size"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("BrushSize", {
                                                        attrs: {
                                                          sizes: [2, 4, 8, 16],
                                                        },
                                                        model: {
                                                          value: _vm.lineWidth,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.lineWidth = $$v
                                                          },
                                                          expression:
                                                            "lineWidth",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1783367942
                                ),
                              },
                              [
                                _c("span", { staticClass: "bottom" }, [
                                  _vm._v(" Select brush size "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text--lighten-1 grey--text ml-4",
                                    },
                                    [_vm._v("6")]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.isPreferredHost
                    ? [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "", "content-class": "--sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "RtbControlButton",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          xs: "",
                                                          variant:
                                                            _vm.usersCanDraw
                                                              ? "active"
                                                              : "grey",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.toggleUsersCanDraw,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("SvgIcon", {
                                                        attrs: {
                                                          size: "xs",
                                                          name:
                                                            hover &&
                                                            _vm.usersCanDraw
                                                              ? "user-no-edit"
                                                              : "user-edit",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1093553517
                            ),
                          },
                          [
                            _c("span", { staticClass: "bottom" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.usersCanDraw
                                      ? "Remove user ability to draw"
                                      : "Allow users to draw"
                                  ) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text--lighten-1 grey--text ml-2",
                                },
                                [_vm._v("7")]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "", "content-class": "--sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "RtbControlButton",
                                        _vm._g(
                                          {
                                            staticClass: "mb-2",
                                            attrs: { xs: "", variant: "grey" },
                                            on: { click: _vm.updateRotate },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "14", dark: "" } },
                                            [_vm._v(" rotate_90_degrees_ccw ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4169664656
                            ),
                          },
                          [
                            _c("span", { staticClass: "bottom" }, [
                              _vm._v(" Rotate media "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text--lighten-1 grey--text ml-4",
                                },
                                [_vm._v("8")]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "", "content-class": "--sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "RtbControlButton",
                                        _vm._g(
                                          {
                                            staticClass: "mb-2",
                                            attrs: { xs: "", variant: "grey" },
                                            on: { click: _vm.resetMedia },
                                          },
                                          on
                                        ),
                                        [
                                          _c("SvgIcon", {
                                            attrs: {
                                              name: "refresh",
                                              width: "12",
                                              height: "12",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4192727268
                            ),
                          },
                          [
                            _c("span", { staticClass: "bottom" }, [
                              _vm._v(" Reset media "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text--lighten-1 grey--text ml-4",
                                },
                                [_vm._v("`")]
                              ),
                            ]),
                          ]
                        ),
                        _vm.isVideo
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "content-class": "--sm" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "RtbControlButton",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  xs: "",
                                                  variant: _vm.volume
                                                    ? "active"
                                                    : "grey",
                                                },
                                                on: { click: _vm.toggleMute },
                                              },
                                              on
                                            ),
                                            [
                                              _c("SvgIcon", {
                                                attrs: {
                                                  name: _vm.volume
                                                    ? "speaker"
                                                    : "speaker-mute",
                                                  width: "14",
                                                  height: "14",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1144691115
                                ),
                              },
                              [
                                _c("span", { staticClass: "bottom" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.volume
                                          ? "Mute video"
                                          : "Unmute video"
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text--lighten-1 grey--text ml-4",
                                    },
                                    [_vm._v("9")]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "", "content-class": "--sm" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var hover = ref.hover
                                                return [
                                                  _c(
                                                    "RtbControlButton",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mt-2",
                                                        attrs: {
                                                          xs: "",
                                                          variant: _vm.slideShow
                                                            ? "active"
                                                            : "grey",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.startSlideShow,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("SvgIcon", {
                                                        attrs: {
                                                          size: "xs",
                                                          name:
                                                            hover &&
                                                            _vm.slideShow
                                                              ? "no-slideshow"
                                                              : "slideshow",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1635517523
                            ),
                          },
                          [
                            _c("span", { staticClass: "bottom" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.slideShow
                                      ? "Disable slideshow"
                                      : "Enable slideshow"
                                  ) +
                                  " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text--lighten-1 grey--text ml-2",
                                },
                                [_vm._v("0")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.isDrawableVisible
            ? _c(
                "div",
                {
                  staticClass: "t-v-slide__drawable-container-wrap",
                  style: _vm.drawableContainerStyle,
                },
                [
                  _c("Drawable", {
                    staticClass: "t-v-slide__cursor",
                    class: _vm.cursor
                      ? "t-v-slide__cursor--" + _vm.cursor
                      : null,
                    style: _vm.drawableStyle,
                    attrs: {
                      bgColor: "transparent",
                      width: _vm.dimensions.originalWidth,
                      height: _vm.dimensions.originalHeight,
                      pen: true,
                      lineWidth: parseInt(_vm.lineWidth),
                      canDraw: _vm.canDraw,
                      alpha: 1,
                      lineColorRgb: _vm.lineColor,
                      points: _vm.pointsComputed,
                    },
                    on: {
                      mouseUp: _vm.onDrawableMouseUp,
                      mouseDown: _vm.onDrawableMouseDown,
                      ready: _vm.onDrawableReady,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }