<template>
  <div class="t-voting" :class="{ 't-voting--overlayed': isPresenterCentered }">
    <div class="t-voting__tools" v-if="isHost">
      <RtbButton
        variant="icon"
        :color="isUsersHidden ? 'primary' : 'grey'"
        @click="toggleUsers"
      >
        <v-icon>person_off</v-icon></RtbButton
      >
    </div>
    <div class="t-voting__header" v-if="!isSlideShow">
      <div ref="caption" class="t-voting__caption">
        {{ slideCaption }}
      </div>
      <div class="t-voting__counter">{{ index + 1 }}/{{ nOfSlides }}</div>
    </div>
    <div v-else style="margin-top: 20px"></div>
    <div class="t-voting__cnt">
      <transition name="t-voting__transition" mode="out-in">
        <TheaterVotingSlide
          v-if="slide && slide.id"
          :id="slide.id"
          :slide="slide"
          :slideShow="isSlideShow"
          :overrideImageUrl="overrideImageUrl"
          :key="`${slide.id}-slide`"
          @startSlideShow="startSlideShow"
          @rated="rated"
        />
      </transition>
      <div class="t-voting__controls" v-if="isHost">
        <SvgIcon
          @click="prevSlide"
          class="t-voting__arrow"
          name="arrow-right"
        />
        <SvgIcon @click="nextSlide" class="t-voting__arrow" name="arrow-left" />
      </div>
      <HostMedia
        class="t-voting__presenter-media"
        :class="{
          't-voting__presenter-media--slide-show':
            isSlideShow && !isPresenterCentered,
          't-voting__presenter-media--centered': isPresenterCentered
        }"
        :rounded="!isPresenterCentered"
      />
    </div>
    <div
      class="t-voting__users"
      :class="{ 't-voting__users--hidden': isUsersHidden }"
    >
      <template v-if="!isUsersHidden">
        <div
          class="t-voting__user-item"
          v-for="player in players"
          :key="player.id"
        >
          <MeetingUserCard
            :user="player"
            @click="updateUserSelection(player.id)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { RtbButton } from "@/components/ui"
import { KEY_I, KEY_U, KEY_LEFT, KEY_RIGHT } from "keycode-js"
import { Role } from "@/helpers"
import TheaterServie from "@/services/theater.service"
import useTheaterVotingSlides from "./useTheaterVotingSlides"
import { db } from "@/firebase"

import VotingMixin from "@/components/GroupTeams/Common/GameModes/Voting/VotingMixin"

import TheaterVotingSlide from "./TheaterVotingSlide"

const OVERRIDE_IMAGE_URL = require("@/assets/voting-theater-image.png")

export default Vue.extend({
  name: "TheaterVoting",
  mixins: [VotingMixin],
  components: {
    RtbButton,
    TheaterVotingSlide,
    MeetingUserCard: () =>
      import(
        "@/components/GroupTeams/Common/Player/MeetingUserCard/MeetingUserCard"
      ),
    HostMedia: () => import("@/components/GroupTeams/Common/HostMedia.vue")
  },
  setup() {
    const { nOfSlides, currentOneAtTimePlayIndex, currentSlide, slides } =
      useTheaterVotingSlides()
    return { nOfSlides, currentOneAtTimePlayIndex, currentSlide, slides }
  },
  data() {
    return {
      localMissionID: null,
      votes: {},
      missions: {},
      unsubscribe: [],
      db: db
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters([
      "actualGame",
      "orgID",
      "gameID",
      "gameStatus",
      "moderatorID",
      "missionPlays",
      "getCurrentMission",
      "onlineUsersArray",
      "plays"
    ]),
    index: {
      get() {
        return this.currentOneAtTimePlayIndex
      },
      set(value) {
        if (!this.isHost) return
        if (value > this.nOfSlides - 1) return
        if (value < 0) return

        TheaterServie.updateVotableItemIndex(value)
      }
    },
    slideCaption() {
      return this.mediaCaption ? this.mediaCaption : this.title
    },
    slideShowIndex() {
      return this.gameStatus.slideShowIndex
    },
    overrideImageUrl() {
      return this.actualGame?.overrideImageUrl?.url
    },
    playsArray() {
      return Object.values(this.plays).filter(
        play => Array.isArray(play.answer) && play.answer[0]?.image
      )
    },
    title() {
      return this.getCurrentMission?.title
    },
    mediaCaption() {
      const currentMedia = this.slide?.answer?.[0] || this.slide?.correct
      return currentMedia?.caption || ""
    },
    slide() {
      if (this.isSlideShow) return this.playsArray[this.slideShowIndex]

      return this.currentSlide
    },

    isSlideShow() {
      return this.gameStatus.slideShow
    },

    players() {
      const gameHostID = this.$store.getters.gameHost?.id
      return this.onlineUsersArray.filter(
        user =>
          user.id !== gameHostID &&
          ![Role.Audit, Role.Spectator].includes(user.role)
      )
    },
    titleWatcher() {
      return `${this.slideCaption}-${this.$window.width}-${this.$window.height}`
    },
    isPresenterCentered() {
      return Boolean(this.gameStatus?.presenterCentered)
    },
    isUsersHidden() {
      return this.gameStatus?.theaterUsersHidden || this.players.length === 0
    }
  },
  watch: {
    // TODO - rework this
    slideShowIndex() {
      if (this.isHost && this.isSlideShow) {
        var i = this.slideShowIndex + 1
        setTimeout(() => {
          this.$store.dispatch("updateGameStatusAny", {
            slideShowIndex: i
          })
        }, 6000)
      }
    },
    titleWatcher: {
      handler() {
        this.$nextTick(() => {
          const el = this.$refs?.caption
          const clientHeight = el?.clientHeight
          const fontSize = clientHeight >= 65 ? "16px" : "22px"
          el && this.$refs.caption.style.setProperty("font-size", fontSize)
        })
      },
      immediate: true
    }
  },
  created() {
    this.$_VotingMixin_fetchMissions()
    if (this.isHost) {
      this.$store.dispatch("updateGameStatusAny", {
        slideShowIndex: 0
      })
      window.addEventListener("keyup", this.onKeyUp)
      this.unsubscribe.push(() =>
        window.removeEventListener("keyup", this.onKeyUp)
      )
    }
  },
  beforeDestroy() {
    // super weird
    this.index = null
    this.unsubscribe.forEach(f => f())
  },
  methods: {
    rated({ rate, data }) {
      this.$_VotingMixin_rated(rate, data)
    },
    startSlideShow() {
      this.$store.dispatch("updateGameStatusAny", {
        slideShowIndex: this.slideShowIndex + 1,
        slideShow: !this.isSlideShow
      })
    },
    prevSlide() {
      if (this.overrideImageUrl) this.onSetOverrideImage(null)
      this.index = this.index - 1
    },
    nextSlide() {
      if (this.overrideImageUrl) this.onSetOverrideImage(null)
      this.index = this.index + 1
    },
    async onKeyUp(e) {
      if (
        ["text", "textarea", "search", "number"].includes(e?.target?.type) &&
        ![null, undefined, ""].includes(e?.target?.value)
      ) {
        return
      }

      if (e.ctrlKey && e.keyCode === KEY_I) {
        const value = this.actualGame.overrideImageUrl?.url
          ? null
          : OVERRIDE_IMAGE_URL

        this.onSetOverrideImage(value)

        return
      }

      if (e.keyCode === KEY_U && e.shiftKey) {
        this.togglePesenterPosition()
      }

      if (e.keyCode === KEY_LEFT && e.shiftKey && !e.altKey && e.ctrlKey) {
        await this.moveMedia("<")
      }

      if (e.keyCode === KEY_RIGHT && e.shiftKey && !e.altKey && e.ctrlKey) {
        await this.moveMedia(">")
      }

      if (e.keyCode === KEY_LEFT && e.shiftKey && !e.altKey && !e.ctrlKey) {
        await this.prevSlide()
      }

      if (e.keyCode === KEY_RIGHT && e.shiftKey && !e.altKey && !e.ctrlKey) {
        await this.nextSlide()
      }
    },
    onSetOverrideImage(value) {
      TheaterServie.updateOverrideImageUrl(value)
    },
    togglePesenterPosition() {
      TheaterServie.updatePresenterCentered(!this.isPresenterCentered)
    },
    async moveMedia(direction) {
      let position = 0
      if (direction === "<") {
        const first = this.slides[0]
        position = first?.position !== undefined ? first?.position - 1 : -1
      }
      if (direction === ">") {
        const last = this.slides.slice(-1)[0]
        position =
          last?.position !== undefined
            ? last?.position + 1
            : this.slides.length - 1
      }

      const path = `org/${this.orgID}/game/${this.gameID}/play/${this.slide.id}`

      await db.auxiliary().ref(path).update({ position })
    },
    toggleUsers() {
      this.$store.dispatch("updateGameStatusAny", {
        theaterUsersHidden: !this.gameStatus.theaterUsersHidden
      })
    }
  }
})
</script>

<style lang="scss">
.t-voting {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  user-select: none;

  &--overlayed::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    z-index: 2;
  }

  &__transition {
    &-enter-active,
    &-leave-active {
      transition: all ease-in-out 0.8s;
      transform: translateX(0);
      opacity: 1;
    }
    &-enter {
      opacity: 0;
      transform: translateX(100px);
    }
    &-leave-to {
      opacity: 0;
      transform: translateX(-100px);
    }
  }

  &__arrow {
    width: 24px;
    height: 24px;
    margin: 52px;
    cursor: pointer;
    pointer-events: all;
  }

  &__controls {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    margin-top: 16px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;

    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 3px;

    min-width: 50%;
    max-width: 60%;
    height: 56px;

    border-radius: 28px;
    background-color: #292932;
    border: 1px solid rgba(255, 255, 255, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 22px;
    font-weight: bold;

    position: relative;
  }

  &__caption {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: inherit;
    font-size: 22px;
  }

  &__team-name,
  &__rating {
    height: 40px;
    width: 320px;

    border-radius: 20px;
    background-color: #292932;
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding-left: 32px;
    padding-right: 32px;
    z-index: 1;

    left: 50%;
    position: absolute;
  }

  &__team-name {
    margin-top: auto;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    transform: translate(-50%, 50%);
    bottom: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__rating {
    margin-bottom: auto;
    font-size: 16px;
    font-weight: bold;

    transform: translate(-50%, -50%);
    top: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__pts {
    color: #b6d6ff;
  }

  &__cnt {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-left: 128px;
    padding-right: 128px;
  }

  &__counter {
    position: absolute;
    top: 50%;
    left: 100%;

    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    height: 32px;
    min-width: 80px;

    border-radius: 16px;
    background-color: #292932;
    border: 1px solid rgba(183, 183, 183, 0.53);

    font-weight: bold;
    font-size: 22px;
    color: #b6d6ff;

    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 3px;
    z-index: 1;
  }

  &__users {
    overflow-x: auto;
    margin-top: 32px;
    height: 120px;
    display: flex;
    transition: height 0.5s;

    &::before,
    &::after {
      content: "";
      flex: 1;
    }

    &--hidden {
      height: 0;
    }
  }

  &__user-item {
    padding: 8px;
    flex-shrink: 1;
    min-width: 120px;
  }

  &__presenter-media {
    position: absolute;
    height: min(250px, min(20vh, 20vw));
    width: min(250px, min(20vh, 20vw));
    transition: all 0.75s 0.25s;
    top: 7%;
    left: 90%;
    transform: translate(-50%, -50%);

    &--slide-show {
      top: 90px;
      left: calc(100% - 110px);
    }

    &--centered {
      height: min(700px, min(60vh, 60vw));
      width: min(700px, min(60vh, 60vw));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  &__tools {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
