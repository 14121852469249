<template>
  <div
    class="theater-voting-slide-asset"
    :class="{
      [`theater-voting-slide-asset--disabled`]: disabled
    }"
  >
    <img
      key="override-image"
      v-if="srcOverride"
      :src="srcOverride"
      @load="onReady"
      :draggable="false"
    />
    <VideoAsset
      key="video"
      loop
      shortcuts
      v-else-if="isVideo"
      :volume="volume"
      :src="url"
      :id="id"
      :controls="isHost"
      :interrupt="false"
      @canplay="onReady"
      class="theater-voting-slide-asset__video"
    />
    <img :draggable="false" v-else key="image" :src="src" @load="onReady" />
  </div>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import isVideo from "is-video"

export default Vue.extend({
  name: "TheaterVotingSlideAsset",
  components: {
    VideoAsset: () => import("@/components/Assets/VideoAsset")
  },
  props: {
    id: String,
    src: String,
    volume: {
      type: Number,
      default: undefined
    },
    srcOverride: String,
    disabled: Boolean
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    url() {
      const url = this.srcOverride || this.src

      const queryIndex = url.indexOf("?")

      return queryIndex !== -1 ? url.substring(0, queryIndex) : url
    },
    isVideo() {
      return isVideo(this.url)
    }
  },
  methods: {
    onReady(e) {
      this.$emit("ready", e)
    }
  }
})
</script>
<style lang="scss">
.theater-voting-slide-asset {
  user-select: none;
  height: 100%;
  width: 100%;
  border-radius: 18px;
  overflow: hidden;

  &__video {
    height: 100%;
    width: 100%;
  }

  video,
  img {
    user-select: none;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  video {
    &::-webkit-media-controls-panel {
      -moz-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
    &::-webkit-media-controls {
      background: transparent;
    }
    &:focus {
      outline: none;
    }
  }

  &--disabled {
    .theater-voting-slide-asset__video {
      pointer-events: none;
    }
  }

  .plyr__controls {
    padding-bottom: 16px !important;
  }
}
</style>
