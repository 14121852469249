import { db } from "@/firebase"
import { store } from "@/store"

export default class TheaterService {
  static updatePresenterCentered(value) {
    const orgID = store.getters.orgID
    const gameID = store.getters.gameID

    return db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/gameStatus/presenterCentered`)
      .set(value)
  }
  static updateOverrideImageUrl(value) {
    const orgID = store.getters.orgID
    const gameID = store.getters.gameID

    return db
      .auxiliary()
      .ref(`org/${orgID}/games/${gameID}/overrideImageUrl/`)
      .set(value)
  }
  static updateVotableItemIndex(value) {
    const orgID = store.getters.orgID
    const gameID = store.getters.gameID

    return db
      .auxiliary()
      .ref(`org/${orgID}/game/${gameID}/gameStatus/oneAtTimeVotingIndex`)
      .set(value)
  }
}
