import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"

export default function useTheaterVotingSlides() {
  const { store } = useStore()
  const missionPlays = computed(() => store.getters.missionPlays)
  const teams = computed(() => store.getters.chats)

  const slides = computed(() => {
    return missionPlays.value
      .map(play => ({
        ...play,
        userID: play.userID || 0,
        teamName: teams.value[play.teamID]?.name
      }))
      .sort((a, b) => ("" + a.id).localeCompare(b.id))
      .sort((a, b) => {
        const aPosition = a.position === undefined ? 0 : a.position
        const bPosition = b.position === undefined ? 0 : b.position
        return aPosition - bPosition
      })
  })

  const nOfSlides = computed(() => slides.value.length)
  const currentOneAtTimePlayIndex = computed(
    () => store.getters.gameStatus?.oneAtTimeVotingIndex ?? 0
  )

  const currentSlide = computed(
    () => slides.value[currentOneAtTimePlayIndex.value]
  )

  return { slides, nOfSlides, currentOneAtTimePlayIndex, currentSlide }
}
