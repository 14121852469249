var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "theater-voting-slide-asset",
      class:
        ((_obj = {}),
        (_obj["theater-voting-slide-asset--disabled"] = _vm.disabled),
        _obj),
    },
    [
      _vm.srcOverride
        ? _c("img", {
            key: "override-image",
            attrs: { src: _vm.srcOverride, draggable: false },
            on: { load: _vm.onReady },
          })
        : _vm.isVideo
        ? _c("VideoAsset", {
            key: "video",
            staticClass: "theater-voting-slide-asset__video",
            attrs: {
              loop: "",
              shortcuts: "",
              volume: _vm.volume,
              src: _vm.url,
              id: _vm.id,
              controls: _vm.isHost,
              interrupt: false,
            },
            on: { canplay: _vm.onReady },
          })
        : _c("img", {
            key: "image",
            attrs: { draggable: false, src: _vm.src },
            on: { load: _vm.onReady },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }