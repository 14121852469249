var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t-voting",
      class: { "t-voting--overlayed": _vm.isPresenterCentered },
    },
    [
      _vm.isHost
        ? _c(
            "div",
            { staticClass: "t-voting__tools" },
            [
              _c(
                "RtbButton",
                {
                  attrs: {
                    variant: "icon",
                    color: _vm.isUsersHidden ? "primary" : "grey",
                  },
                  on: { click: _vm.toggleUsers },
                },
                [_c("v-icon", [_vm._v("person_off")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isSlideShow
        ? _c("div", { staticClass: "t-voting__header" }, [
            _c("div", { ref: "caption", staticClass: "t-voting__caption" }, [
              _vm._v(" " + _vm._s(_vm.slideCaption) + " "),
            ]),
            _c("div", { staticClass: "t-voting__counter" }, [
              _vm._v(_vm._s(_vm.index + 1) + "/" + _vm._s(_vm.nOfSlides)),
            ]),
          ])
        : _c("div", { staticStyle: { "margin-top": "20px" } }),
      _c(
        "div",
        { staticClass: "t-voting__cnt" },
        [
          _c(
            "transition",
            { attrs: { name: "t-voting__transition", mode: "out-in" } },
            [
              _vm.slide && _vm.slide.id
                ? _c("TheaterVotingSlide", {
                    key: _vm.slide.id + "-slide",
                    attrs: {
                      id: _vm.slide.id,
                      slide: _vm.slide,
                      slideShow: _vm.isSlideShow,
                      overrideImageUrl: _vm.overrideImageUrl,
                    },
                    on: {
                      startSlideShow: _vm.startSlideShow,
                      rated: _vm.rated,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.isHost
            ? _c(
                "div",
                { staticClass: "t-voting__controls" },
                [
                  _c("SvgIcon", {
                    staticClass: "t-voting__arrow",
                    attrs: { name: "arrow-right" },
                    on: { click: _vm.prevSlide },
                  }),
                  _c("SvgIcon", {
                    staticClass: "t-voting__arrow",
                    attrs: { name: "arrow-left" },
                    on: { click: _vm.nextSlide },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("HostMedia", {
            staticClass: "t-voting__presenter-media",
            class: {
              "t-voting__presenter-media--slide-show":
                _vm.isSlideShow && !_vm.isPresenterCentered,
              "t-voting__presenter-media--centered": _vm.isPresenterCentered,
            },
            attrs: { rounded: !_vm.isPresenterCentered },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "t-voting__users",
          class: { "t-voting__users--hidden": _vm.isUsersHidden },
        },
        [
          !_vm.isUsersHidden
            ? _vm._l(_vm.players, function (player) {
                return _c(
                  "div",
                  { key: player.id, staticClass: "t-voting__user-item" },
                  [
                    _c("MeetingUserCard", {
                      attrs: { user: player },
                      on: {
                        click: function ($event) {
                          return _vm.updateUserSelection(player.id)
                        },
                      },
                    }),
                  ],
                  1
                )
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }