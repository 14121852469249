import Vue from "vue"
import Action from "../enums/Action"

const store = Vue.observable({
  action: Action.Default,
  colors: { rgba: { r: 103, g: 172, b: 91 } },
  lineWidth: 8
})

export default store
