<template>
  <div class="t-v-slide" ref="wrapper">
    <div
      class="t-v-slide__content"
      :class="{ [`t-v-slide__content--${cursor}`]: cursor }"
      :style="contentStyle"
      @touchstart="onMouseDown"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @touchend="onMouseUp"
      @mousemove="onMouseMove"
      @touchmove="onMouseMove"
    >
      <div class="t-v-slide__team-name" v-if="isTeamNameVisible">
        <div class="ellipsis">
          {{ slide.teamName }}
        </div>
      </div>

      <div class="t-v-slide__rating" v-if="ratingVisible">
        Vote
        <StarRating
          class="t-v-slide__vue-star-rating"
          :read-only="false"
          :increment="0.5"
          :padding="1"
          :star-size="20"
          :show-rating="false"
          :border-width="0"
          :inactive-color="$theme.get('--disabled-color')"
          active-color="#fff"
          border-color="transparent"
          @rating-selected="$emit('rated', { rate: $event, data: slide })"
        />

        <div class="t-v-slide__pts" v-if="isScoreVisible">
          {{ slide.score || 0 }} PTS
        </div>
      </div>
      <div class="t-v-slide__wrapper">
        <TheaterVotingSlideAsset
          v-if="isMediaContent"
          :class="{ [`t-v-slide__cursor--${cursor}`]: cursor }"
          :style="zoomableStyle"
          :id="id"
          :src="src"
          :srcOverride="overrideImageUrl"
          :disabled="action && action !== Action.Default"
          ref="asset"
          :volume="volume"
          @ready="onReady"
        />
        <ResizableText v-else :message="slide.correct" class="w-full h-full" />
      </div>

      <div
        class="t-v-slide__controls"
        v-if="dimensions && !overrideImageUrl"
        style="pointer-events: none"
        @mouseover="onControlsMouseOver"
        @mouseleave="onControlsMouseLeave"
      >
        <div class="t-v-slide__controls-row">
          <v-tooltip bottom content-class="--sm">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <SettingsSelector
                  v-if="isPreferredHost"
                  v-model="action"
                  style="width: 50px"
                  class="mr-2"
                  :items="cursorOptions"
                >
                  <template #selected-item="{ item }">
                    <v-icon size="14" dark class="ml-1">
                      {{ item.icon }}
                    </v-icon>
                  </template>
                  <template #item="{ item }">
                    <v-icon size="14" dark class="text-sm mr-2">
                      {{ item.icon }}
                    </v-icon>
                    <span class="mr-4">
                      {{ item.name }}
                    </span>
                    <span class="ml-auto">
                      {{ item.hint }}
                    </span>
                  </template>
                </SettingsSelector>
              </span>
            </template>
            <span class="bottom"> Select image cursor </span>
          </v-tooltip>

          <template v-if="canDraw">
            <v-tooltip bottom content-class="--sm">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <SettingsSelector
                    style="width: 50px"
                    class="mr-2"
                    ref="colorPalette"
                  >
                    <template #selected-item>
                      <v-icon size="14" dark :color="lineColor" class="ml-1">
                        fa-palette
                      </v-icon>
                    </template>
                    <div class="px-3 pb-3 pt-1">
                      <div class="font-bold mb-1">Annotation Tool Color</div>
                      <ColorPalette v-model="lineColor" />
                    </div>
                  </SettingsSelector>
                </span>
              </template>
              <span class="bottom">
                Select brush color
                <span class="text--lighten-1 grey--text ml-4">5</span>
              </span>
            </v-tooltip>

            <v-tooltip bottom content-class="--sm">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <SettingsSelector style="width: 50px" ref="brushSize">
                    <template #selected-item>
                      <v-icon
                        :size="lineWidth + 2"
                        dark
                        :color="lineColor"
                        class="mx-1"
                      >
                        circle
                      </v-icon>
                    </template>
                    <div class="px-3 pb-3 pt-1">
                      <div class="font-bold mb-1">Annotation Tool Size</div>
                      <BrushSize v-model="lineWidth" :sizes="[2, 4, 8, 16]" />
                    </div>
                  </SettingsSelector>
                </span>
              </template>
              <span class="bottom">
                Select brush size
                <span class="text--lighten-1 grey--text ml-4">6</span>
              </span>
            </v-tooltip>
          </template>
        </div>

        <template v-if="isPreferredHost">
          <v-tooltip bottom content-class="--sm">
            <template v-slot:activator="{ on }">
              <v-hover #default="{ hover }">
                <RtbControlButton
                  v-on="on"
                  xs
                  class="mb-2"
                  :variant="usersCanDraw ? 'active' : 'grey'"
                  @click="toggleUsersCanDraw"
                >
                  <SvgIcon
                    size="xs"
                    :name="hover && usersCanDraw ? 'user-no-edit' : 'user-edit'"
                  />
                </RtbControlButton>
              </v-hover>
            </template>
            <span class="bottom">
              {{
                usersCanDraw
                  ? "Remove user ability to draw"
                  : "Allow users to draw"
              }}
              <span class="text--lighten-1 grey--text ml-2">7</span>
            </span>
          </v-tooltip>

          <v-tooltip bottom content-class="--sm">
            <template v-slot:activator="{ on }">
              <RtbControlButton
                v-on="on"
                xs
                class="mb-2"
                variant="grey"
                @click="updateRotate"
              >
                <v-icon size="14" dark> rotate_90_degrees_ccw </v-icon>
              </RtbControlButton>
            </template>
            <span class="bottom">
              Rotate media
              <span class="text--lighten-1 grey--text ml-4">8</span>
            </span>
          </v-tooltip>

          <v-tooltip bottom content-class="--sm">
            <template v-slot:activator="{ on }">
              <RtbControlButton
                v-on="on"
                xs
                class="mb-2"
                variant="grey"
                @click="resetMedia"
              >
                <SvgIcon name="refresh" width="12" height="12" />
              </RtbControlButton>
            </template>
            <span class="bottom">
              Reset media
              <span class="text--lighten-1 grey--text ml-4">`</span>
            </span>
          </v-tooltip>

          <v-tooltip bottom v-if="isVideo" content-class="--sm">
            <template v-slot:activator="{ on }">
              <RtbControlButton
                xs
                v-on="on"
                :variant="volume ? 'active' : 'grey'"
                @click="toggleMute"
              >
                <SvgIcon
                  :name="volume ? 'speaker' : 'speaker-mute'"
                  width="14"
                  height="14"
                />
              </RtbControlButton>
            </template>
            <span class="bottom">
              {{ volume ? "Mute video" : "Unmute video" }}
              <span class="text--lighten-1 grey--text ml-4">9</span>
            </span>
          </v-tooltip>
          <v-tooltip bottom content-class="--sm">
            <template v-slot:activator="{ on }">
              <v-hover #default="{ hover }">
                <RtbControlButton
                  v-on="on"
                  xs
                  class="mt-2"
                  :variant="slideShow ? 'active' : 'grey'"
                  @click="startSlideShow"
                >
                  <SvgIcon
                    size="xs"
                    :name="hover && slideShow ? 'no-slideshow' : 'slideshow'"
                  />
                </RtbControlButton>
              </v-hover>
            </template>
            <span class="bottom">
              {{ slideShow ? "Disable slideshow" : "Enable slideshow" }}
              <span class="text--lighten-1 grey--text ml-2">0</span>
            </span>
          </v-tooltip>
        </template>
      </div>

      <div
        class="t-v-slide__drawable-container-wrap"
        :style="drawableContainerStyle"
        v-if="isDrawableVisible"
      >
        <Drawable
          bgColor="transparent"
          :style="drawableStyle"
          :width="dimensions.originalWidth"
          :height="dimensions.originalHeight"
          :pen="true"
          :lineWidth="parseInt(lineWidth)"
          :canDraw="canDraw"
          :alpha="1"
          :lineColorRgb="lineColor"
          :points="pointsComputed"
          class="t-v-slide__cursor"
          :class="cursor ? `t-v-slide__cursor--${cursor}` : null"
          @mouseUp="onDrawableMouseUp"
          @mouseDown="onDrawableMouseDown"
          @ready="onDrawableReady"
        />
      </div>
    </div>
    <ResizeObserver @notify="handleResize" />
  </div>
</template>

<script>
import Vue from "vue"
import StarRating from "vue-star-rating"
import { db } from "@/firebase"
import { v4 as uuidv4 } from "uuid"
import TheaterVotingSlideAsset from "./TheaterVotingSlideAsset.vue"
import { ResizeObserver } from "vue-resize"
import { debounce } from "lodash"
import isVideo from "is-video"
import { mapActions } from "vuex"
import {
  KEY_0,
  KEY_1,
  KEY_2,
  KEY_3,
  KEY_4,
  KEY_5,
  KEY_6,
  KEY_7,
  KEY_8,
  KEY_9,
  KEY_BACK_QUOTE
} from "keycode-js"
import { WeveSelectField } from "@weve/ui"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"

import { RtbControlButton } from "@/components/ui"
import SettingsSelector from "@/components/SettingsSelector.vue"
import ColorPalette from "@/components/GroupTeams/Common/GameModes/ColorPalette.vue"
import BrushSize from "@/components/GroupTeams/Common/GameModes/BrushSize.vue"
import { MEDIA_MISSION_TYPES } from "@/components/GroupTeams/Common/GameModes/Voting/Voting.consts"

import User from "@shared/User"

import Action from "./enums/Action"
import store from "./store"

export default Vue.extend({
  name: "TheaterVotingSlide",
  components: {
    ResizableText,
    ResizeObserver,
    SettingsSelector,
    WeveSelectField,
    RtbControlButton,
    StarRating,
    BrushSize,
    ColorPalette,
    TheaterVotingSlideAsset,
    Drawable: () => import("@/components/Drawable/Drawable")
  },
  props: {
    overrideImageUrl: String,
    id: String,
    slide: {
      type: Object,
      default: undefined
    },
    slideShow: Boolean
  },
  data() {
    return {
      db: db.auxiliary(),
      zoom: 1,
      rotate: 0,
      usersCanDraw: false,
      dimensions: null,
      points: {},
      drawingSessionID: null,
      drawableOpacity: 1,
      fadeOutTimeout: null,
      forgetTimeout: null,
      isDrawableReady: false,
      timeout: 5,
      Action,
      isMouseDown: false,
      position: null,
      offset: null,
      isMouseOverControls: false,
      slideHeight: null,
      store,
      slideWidth: null
    }
  },
  computed: {
    isScoreVisible() {
      return this.isHost || !this.currentMission?.hidePointsInVoting
    },
    isMediaContent() {
      return MEDIA_MISSION_TYPES.includes(this.currentMission?.behavior)
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    ratingVisible() {
      return (
        !this.slideShow &&
        !this.game?.noVote &&
        this.slide.teamID != this.user.teamID
      )
    },
    isHost() {
      return User.isHost(this.user)
    },
    user() {
      return this.$store.getters["auth/user"]
    },
    isPreferredHost() {
      return this.user?.id === this.$store.getters.gameHost?.id
    },
    isDrawableVisible() {
      return (
        this.isMediaContent &&
        this.dimensions &&
        !this.overrideImageUrl &&
        (this.canDraw || !this.isPreferredHost)
      )
    },
    lineColor: {
      get() {
        const {
          rgba: { r, g, b }
        } = store.colors
        return `rgb(${r},${g},${b})`
      },
      set([r, g, b]) {
        store.colors = { rgba: { r, g, b, a: 1 } }
      }
    },
    lineWidth: {
      get() {
        return store.lineWidth
      },
      set(value) {
        store.lineWidth = value
      }
    },
    cursorOptions() {
      return [
        {
          icon: "fa-mouse-pointer",
          name: "Default cursor",
          hint: "",
          value: Action.Default
        },
        {
          icon: "fa-pencil-alt",
          name: "Annotate",
          hint: "1",
          value: Action.Pencil
        },
        {
          icon: "fa-search-plus",
          name: "Zoom In",
          hint: "2",
          value: Action.ZoomIn
        },
        {
          icon: "fa-search-minus",
          name: "Zoom Out",
          hint: "3",
          value: Action.ZoomOut
        },
        {
          icon: "fa-hand-paper",
          name: "Move",
          hint: "4",
          value: Action.Hand
        }
      ]
    },
    action: {
      get() {
        return store.action
      },
      set(value) {
        store.action = value
      }
    },
    canDraw() {
      return this.action === Action.Pencil
    },
    cursor() {
      if (this.action === Action.Hand) {
        return this.isMouseDown ? "hand-back-fist" : "hand"
      }

      return this.action
    },
    zoomableStyle() {
      const x = (this.offset?.x ?? 0) / this.zoom
      const y = (this.offset?.y ?? 0) / this.zoom
      const slideWidth = this.contentStyle?.width
      const slideHeight = this.contentStyle?.height
      return {
        transform: `rotate(${this.rotate}deg) scale(${this.zoom}) translate(${x}px, ${y}px)`,
        ...(this.isRotated && {
          width: slideHeight,
          height: slideWidth
        })
      }
    },
    drawableContainerStyle() {
      return {
        pointerEvents: this.action !== Action.Pencil ? "none" : "auto"
      }
    },
    drawableStyle() {
      const opacity = this.drawableOpacity
      return {
        opacity,
        transition: `opacity ${opacity === 0 ? this.timeout : 0.1}s ease`,
        ...this.zoomableStyle
      }
    },
    pointsComputed() {
      return this.isDrawableReady ? this.points : {}
    },
    drawableStateRef() {
      return this.db.ref(`drawings/${this.$store.getters.gameID}/settings`)
    },
    drawableSessionStateRef() {
      return this.db.ref(`drawings/${this.id}/settings`)
    },
    drawableSessionsRef() {
      return this.db.ref(`drawings/${this.id}/sessions`)
    },
    drawableSessionRef() {
      return this.drawingSessionID
        ? this.drawableSessionsRef.child(this.drawingSessionID)
        : null
    },
    src() {
      const obj = this.slide?.correct
      const media = Array.isArray(obj) ? obj[0] : obj
      return media?.video || media?.image
    },
    mediaSize() {
      const { isRotated } = this

      const originalWidth =
        this.dimensions?.[isRotated ? "originalHeight" : "originalWidth"]
      const originalHeight =
        this.dimensions?.[isRotated ? "originalWidth" : "originalHeight"]
      if (!originalWidth || !originalHeight) return

      const wrh = originalWidth / originalHeight

      let width = this.slideWidth
      let height = width / wrh

      if (height > this.slideHeight) {
        height = this.slideHeight
        width = height * wrh
      }

      if (height && width) {
        return { height, width }
      }

      return
    },
    bgSound() {
      return this.slide?.bgSound
    },
    contentStyle() {
      if (this.mediaSize?.height && this.mediaSize?.width) {
        return {
          height: this.mediaSize.height + "px",
          width: this.mediaSize.width + "px"
        }
      }

      return
    },
    media() {
      return this.$store.state.group.media?.[this?.id]
    },
    volume: {
      get() {
        const volume = this.media?.volume
        return volume === undefined ? 0 : volume
      },
      set(volume) {
        this.$store.dispatch("group/updateMissionMediaState", {
          ...this.media,
          volume
        })
      }
    },
    isVideo() {
      return isVideo(this.src)
    },
    isRotated() {
      return [-90, -270].includes(this.rotate)
    },
    isTeamNameVisible() {
      return !this.slideShow && !this.$store.getters.gameStatus?.votingAnon
    }
  },
  watch: {
    action() {
      this.isMouseDown = false
      this.isMouseOverControls = false
    },
    drawableSessionRef: {
      handler(newValue, oldValue) {
        if (!oldValue) this.points = {}
        oldValue?.off("value", this.onDrawableSessionSnapshot)
        newValue?.on("value", this.onDrawableSessionSnapshot)
      },
      immediate: true
    },
    usersCanDraw: {
      immediate: true,
      handler(value) {
        if (this.isPreferredHost) return
        if (value) {
          store.action = Action.Pencil
        } else {
          store.action = Action.Default
        }
      }
    },
    bgSound: {
      immediate: true,
      handler(next, prev) {
        if (this.isHost && next && next !== prev && !this.slideShow) {
          const { id, source, volume, name } = next
          this.updateGameAudioTrack({
            name: name || "",
            source,
            key: id,
            volume,
            overlay: false,
            status: "play",
            playing: false
          })
        }
      }
    }
  },
  created() {
    this.drawableSessionStateRef.on(
      "value",
      this.onDrawableSessionStateSnapshot
    )
    this.drawableStateRef.on("value", this.onDrawableStateSnapshot)

    if (this.isHost) window.addEventListener("keyup", this.onKeyUp)
  },
  mounted() {
    this.slideHeight = this.$refs.wrapper?.offsetHeight || 0
    this.slideWidth = this.$refs.wrapper?.offsetWidth || 0
  },
  beforeDestroy() {
    this.drawableSessionStateRef.off(
      "value",
      this.onDrawableSessionStateSnapshot
    )
    this.drawableStateRef.off("value", this.onDrawableStateSnapshot)
    this.drawableSessionRef?.off("value", this.onDrawableSessionSnapshot)
    window.removeEventListener("keyup", this.onKeyUp)
  },
  methods: {
    ...mapActions("soundeffect", ["updateGameAudioTrack"]),
    offestToPercents({ x = 0, y = 0 }) {
      const height = (x / this.mediaSize.height) * 100
      const width = (y / this.mediaSize.width) * 100
      return { x: height, y: width }
    },
    offestToPixels({ x = 0, y = 0 }) {
      const height = (this.mediaSize?.height / 100) * x
      const width = (this.mediaSize?.width / 100) * y
      return { x: height, y: width }
    },
    startSlideShow() {
      this.$emit("startSlideShow")
    },
    eraseDrawable() {
      this.drawableSessionsRef.set(null)
    },
    resetMedia() {
      this.eraseDrawable()
      this.drawableSessionStateRef.update({
        offset: null,
        zoom: 1,
        rotate: 0
      })
    },
    onControlsMouseOver() {
      this.isMouseOverControls = true
    },
    onControlsMouseLeave() {
      this.isMouseOverControls = false
    },
    onDrawableSessionSnapshot(snapshot) {
      this.points = snapshot.val() ?? {}
    },
    onMouseDown(e) {
      this.isMouseDown = true
      if (this.isMouseOverControls) return

      if (this.action === Action.Hand) {
        const offset = this.offset ?? { x: 0, y: 0 }
        let x = e.screenX - offset.x
        let y = e.screenY - offset.y

        if (this.rotate === -90) {
          x = e.screenX - offset.y
          y = e.screenY + offset.x
        }

        if (this.rotate === -180) {
          x = e.screenX + offset.x
          y = e.screenY + offset.y
        }

        if (this.rotate === -270) {
          x = e.screenX + offset.y
          y = e.screenY - offset.x
        }

        this.position = {
          x,
          y
        }
      } else if (
        this.action === Action.ZoomIn ||
        this.action === Action.ZoomOut
      ) {
        const zoom =
          this.action === Action.ZoomIn ? this.zoom + 0.5 : this.zoom - 0.5

        if (zoom < 1) return

        this.updateZoom(zoom)

        if (zoom === 1) {
          this.drawableSessionStateRef.child("offset").set({ x: 0, y: 0 })
        } else if (Action.ZoomIn) {
          const rect = this.$refs.asset?.$el?.getBoundingClientRect()
          const x = e.clientX - rect.x - rect.width / 2
          const y = e.clientY - rect.y - rect.height / 2
          this.drawableSessionStateRef
            .child("offset")
            .set(this.offestToPercents({ x: x * -1, y: y * -1 }))
        }
      }
    },
    onMouseUp() {
      this.isMouseDown = false
      if (this.isMouseOverControls) return

      if (this.action === Action.Hand) {
        const offset = this.offestToPercents(this.offset ?? { x: 0, y: 0 })
        this.drawableSessionStateRef.child("offset").set(offset)
      }
    },
    onMouseMove(e) {
      if (this.isMouseOverControls) return
      if (this.action !== Action.Hand) return
      if (!this.isMouseDown) return

      const position = this.position || { x: 0, y: 0 }

      let x = e.screenX - position.x
      let y = e.screenY - position.y

      if (this.rotate === -90) {
        x = (e.screenY - position.y) * -1
        y = e.screenX - position.x
      }

      if (this.rotate === -180) {
        x = (e.screenX - position.x) * -1
        y = (e.screenY - position.y) * -1
      }

      if (this.rotate === -270) {
        x = e.screenY - position.y
        y = (e.screenX - position.x) * -1
      }

      this.offset = {
        x,
        y
      }
    },
    updateZoom(value) {
      this.zoom = value
      this.drawableSessionStateRef.child("zoom").set(value)
    },
    updateRotate() {
      const next = this.rotate - 90
      const circleDeg = 360
      this.rotate = next % circleDeg
      this.drawableSessionStateRef.child("rotate").set(this.rotate)
    },
    updateSession() {
      const sessionID = this.drawingSessionID || uuidv4()
      this.drawingSessionID = sessionID
      this.drawableSessionStateRef.update({
        sessionID,
        updatedAt: Date.now()
      })
    },
    onDrawableReady() {
      this.isDrawableReady = true
    },
    onDrawableMouseDown() {
      this.updateSession()
    },
    onDrawableMouseUp({ points, lineColor, lineWidth }) {
      this.updateSession()
      const obj = { points, lineColor, lineWidth }
      this.drawableSessionRef.push({ data: JSON.stringify(obj) })
    },
    onDrawableStateSnapshot(snapshot) {
      const data = snapshot.val() || {}
      this.usersCanDraw = Boolean(data.usersCanDraw)
    },
    onDrawableSessionStateSnapshot(snapshot) {
      clearTimeout(this.fadeOutTimeout)
      clearTimeout(this.forgetTimeout)
      const TIMEOUT = 1000 * this.timeout
      const data = snapshot.val() || {}
      const now = Date.now()

      this.offset = this.offestToPixels(data.offset || {})
      this.zoom = data.zoom > 1 ? data.zoom : 1
      this.rotate = data.rotate ?? 0

      if (!(data.updatedAt && data.updatedAt > now - TIMEOUT)) return
      this.drawingSessionID = data?.sessionID

      this.drawableOpacity = 1
      this.fadeOutTimeout = setTimeout(() => {
        this.drawableOpacity = 0
        this.forgetTimeout = setTimeout(() => {
          this.drawingSessionID = null
        }, TIMEOUT)
      }, TIMEOUT)
    },
    toggleUsersCanDraw() {
      this.drawableStateRef.child("usersCanDraw").set(!this.usersCanDraw)
    },
    onReady(e) {
      const el = e.target
      this.dimensions = {
        width: el.offsetWidth,
        height: el.offsetHeight,
        originalWidth: el.videoWidth || el.naturalWidth,
        originalHeight: el.videoHeight || el.naturalHeight
      }
    },
    handleResize: debounce(function ({ height, width }) {
      this.slideHeight = height
      this.slideWidth = width
    }, 500),
    toggleMute() {
      this.volume = this.volume ? 0 : 1
    },
    onKeyUp(e) {
      if (
        ["text", "textarea", "search", "number"].includes(e?.target?.type) &&
        ![null, undefined, ""].includes(e?.target?.value)
      ) {
        return
      }

      switch (e.keyCode) {
        case KEY_0:
          this.startSlideShow()
          break
        case KEY_1:
          this.action = Action.Pencil
          break

        case KEY_2:
          this.action = Action.ZoomIn
          break

        case KEY_3:
          this.action = Action.ZoomOut
          break

        case KEY_4:
          this.action = Action.Hand
          break

        case KEY_5:
          this.$refs.colorPalette.$data.show =
            !this.$refs.colorPalette.$data.show
          break

        case KEY_6:
          this.$refs.brushSize.$data.show = !this.$refs.brushSize.$data.show
          break

        case KEY_7:
          this.toggleUsersCanDraw()
          break

        case KEY_8:
          this.updateRotate()
          break

        case KEY_9:
          this.toggleMute()
          break

        case KEY_BACK_QUOTE:
          this.resetMedia()
          break
      }
    }
  }
})
</script>
<style lang="scss">
.t-v-slide {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .vs__dropdown-option {
    position: relative;
    height: initial;
    padding: 0;
    border-radius: 4px;
  }

  .weve-select__option {
    padding: 6px 20px 6px 8px;
  }

  &__vue-star-rating {
    position: relative;
    top: 1px; // crazy
  }

  &__team-name,
  &__rating {
    height: 40px;
    width: 320px;

    border-radius: 20px;
    background-color: #292932;
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding-left: 32px;
    padding-right: 32px;
    z-index: 1;

    left: 50%;
    position: absolute;
  }

  &__team-name {
    margin-top: auto;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;

    transform: translate(-50%, 50%);
    bottom: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__rating {
    margin-bottom: auto;
    font-size: 16px;
    font-weight: bold;

    transform: translate(-50%, -50%);
    top: 100%;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__pts {
    color: #b6d6ff;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #292932;
  }

  &__content {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 20px;
    border: 2px solid #f8f9fb;
    width: 100%;
    height: 100%;
    transition: width, height, 0.5s;

    &--hand {
      cursor: url("../../../../../assets/cursors/hand.svg"), auto;
    }

    &--hand-back-fist {
      cursor: url("../../../../../assets/cursors/hand-back-fist.svg"), auto;
    }

    &--zoom-out {
      cursor: url("../../../../../assets/cursors/search-minus.svg"), auto;
    }

    &--zoom-in {
      cursor: url("../../../../../assets/cursors/search-plus.svg"), auto;
    }
  }

  .synced-canvas__canvas {
    border: initial;
    border-radius: initial;
    width: 100%;
    height: 100%;
  }

  &__color-picker {
    position: absolute;
    top: 24px;
    left: 0px;
    z-index: 999;
    pointer-events: all;
  }

  &__drawable-container-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__thickness-input {
    pointer-events: all;
    text-align: center;
    width: 30px;
    height: 18px;
    margin-right: 10px;
  }

  &__controls {
    z-index: 99;
    text-align: left;

    left: 16px;
    top: 32px;
    position: absolute;
    pointer-events: none;

    button {
      pointer-events: auto;
    }
  }

  &__controls-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    pointer-events: auto;
  }

  &__btn {
    cursor: pointer;
    pointer-events: all;
    margin-right: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
    &--active {
      color: $primary-accent-color !important;
    }
  }

  &__cursor {
    &--draw {
      cursor: url("../../../../../assets/cursors/pencil.svg") -5 20, auto;
    }
  }
}
</style>
